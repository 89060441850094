import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

export const routes: Routes = [
    //{ path: 'login', loadComponent: () => import('./dashboard/login/login.component').then(cp => cp.LoginComponent), canActivate: [MsalGuard] },
    { path: 'home', loadComponent: () => import('./dashboard/view/home/home.component').then(cp => cp.HomeComponent), canActivate: [MsalGuard] },
    { path: 'dashdesp3', loadComponent: () => import('@views/dash-desp3/dash-desp3.component').then(cp => cp.DashDesp3Component), canActivate: [MsalGuard] },
    { path: 'calculos-gerais', loadComponent: () => import('@views/calculos-gerais/calculos-gerais.component').then(cp => cp.CalculosGeraisComponent), canActivate: [MsalGuard] },
    { path: 'calculos-provisao', loadComponent: () => import('@views/calculo-provisao/calculo-provisao.component').then(cp => cp.CalculoProvisaoComponent), canActivate: [MsalGuard] },
    { path: 'admin', loadComponent: () => import('@views/admin/admin.component').then(cp => cp.AdminComponent), canActivate: [MsalGuard] },
    { path: 'contas', loadComponent: () => import('@views/dash-projection/contas/contas.component').then(cp => cp.ContasComponent) },
    { path: 'centro-custo', loadComponent: () => import('@views/dash-projection/centro-custo/centro-custo.component').then(cp => cp.CentroCustoComponent), canActivate: [MsalGuard] },
    { path: 'projecao', loadComponent: () => import('@views/dash-projection/projecao/projecao.component').then(cp => cp.ProjecaoComponent), canActivate: [MsalGuard] },
    { path: 'ciclos', loadComponent: () => import('./dashboard/view/dash-projection/ciclos/ciclos.component').then(cp => cp.CiclosComponent), canActivate: [MsalGuard] },
    { path: 'usuarios', loadComponent: () => import('@views/dash-projection/usuarios/usuarios.component').then(cp => cp.UsuariosComponent) },
    { path: '', redirectTo: '/home', pathMatch: 'full' },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // Don't perform initial navigation in iframes or popups
            initialNavigation:
                !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
                    ? 'enabledNonBlocking'
                    : 'disabled', // Set to enabledBlocking to use Angular Universal
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {

}