import { CommonModule } from '@angular/common';
import { Component, effect, signal } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
    selector: 'app-loading-notify',
    imports: [CommonModule],
    templateUrl: './loading-notify.component.html',
    styleUrl: './loading-notify.component.css'
})
export class LoadingNotifyComponent {
  textoLoading = signal<string>('Loading...');

  constructor(public loader : LoadingService) {
    effect( () => {
        console.log('>>>> Executando loading ');
    });

   }



}
