import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  mostrarTitulo = signal<boolean>(false);
  novoTitulo = signal<boolean>(false);


  private loadinSubject = new BehaviorSubject<boolean>(true);
  loadSub$ : Observable<boolean> = this.loadinSubject.asObservable();

  constructor() { 
    this.hide();
   }

  hide(): void {
    console.log('>>>>> Loading off');
    this.loadinSubject.next(false);

  }

  show(): void {
    console.log('>>>>> Loading on');
    this.loadinSubject.next(true);

  }


}
